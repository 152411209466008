import axios from 'axios';
import { API_BASE_URL } from '../../constants/appConstants';
import axiosInstance from '../../helpers/axios';
import {
  ADD_DASHBOARD_FINISHED,
  ADD_DASHBOARD_START,
  FETCH_DASHBOARD_MY_REQUESTS_FINISHED,
  FETCH_DASHBOARD_MY_REQUESTS_START,
  FETCH_DASHBOARD_USER_REQUESTS_FINISHED,
  FETCH_DASHBOARD_USER_REQUESTS_START,
  GET_DASHBOARDS_LIST_FINISHED,
  GET_DASHBOARDS_LIST_START,
  GET_DASHBOARD_BY_ID_FINISHED,
  GET_DASHBOARD_BY_ID_START,
  GET_DASHBOARD_CATEGORIES_FINISHED,
  GET_DASHBOARD_CATEGORIES_START,
  GET_DASHBOARD_CAT_OPTIONS_FINISHED,
  GET_DASHBOARD_CAT_OPTIONS_START,
  GET_DASHBOARD_MY_REQUEST_STATUSES_FINISHED,
  GET_DASHBOARD_MY_REQUEST_STATUSES_START,
  GET_DASHBOARD_USER_REQUEST_STATUSES_FINISHED,
  GET_DASHBOARD_USER_REQUEST_STATUSES_START,
  GET_DASH_OWNERS_FINISHED,
  GET_DASH_OWNERS_START,
  GET_DASH_USERS_FINISHED,
  GET_DASH_USERS_START,
  GET_REFRESH_CYCLE_OPTIONS_FINISHED,
  GET_REFRESH_CYCLE_OPTIONS_START,
  REQUEST_DASHBOARD_ACCESS_FINISHED,
  REQUEST_DASHBOARD_ACCESS_START,
  UPDATE_REQUEST_STATUS_FINISHED,
  UPDATE_REQUEST_STATUS_START,
  GET_CUSTOM_DASH_AD_GROUP_START,
  GET_CUSTOM_DASH_AD_GROUP_FINISHED,
  CREATE_NEW_DASH_AD_GROUP_START,
  CREATE_NEW_DASH_AD_GROUP_FINISHED,
  DEACTIVATE_DASH_AD_GROUP_START,
  DEACTIVATE_DASH_AD_GROUP_FINISHED,
  AD_DASH_GROUP_ADD_MEMBERS_STATUS_START,
  AD_DASH_GROUP_ADD_MEMBERS_STATUS_FINISHED,
  GET_CUSTOM_DASH_AD_MEMBERS_START,
  GET_CUSTOM_DASH_AD_MEMBERS_FINISHED,
  DEACTIVATE_DASH_AD_MEMBERS_START,
  DEACTIVATE_DASH_AD_MEMBERS_FINISHED,
} from '../constants/types';

const getDashboardsList =
  (payload = {}) =>
  (dispatch) => {
    const { category, appSearch } = payload;
    dispatch({ type: GET_DASHBOARDS_LIST_START });
    let url = `dashboard/dashboard?dashboard_type=${'sarovar'}`;
    if (category) {
      url += '&dashboard_group=' + category;
    } else if (appSearch) {
      url += '&dashb_name_search=' + appSearch;
    }
    axiosInstance
      .get(url, {
        withCredentials: false,
      })
      .then(
        (res) => {
          dispatch({
            type: GET_DASHBOARDS_LIST_FINISHED,
            data: res?.data?.permitted_dashboards,
          });
        },
        (err) => {
          console.log('Error', err);
        }
      );
  };

const getCategoryCounts = (payload) => (dispatch) => {
  dispatch({ type: GET_DASHBOARD_CATEGORIES_START });
  axiosInstance.get('dashboard/sarovar_group_wise_count', {}).then(
    (res) => {
      dispatch({
        type: GET_DASHBOARD_CATEGORIES_FINISHED,
        data: res.data?.data || {},
      });
    },
    (err) => {
      console.log('category counts error', err);
      dispatch({ type: GET_DASHBOARD_CATEGORIES_FINISHED, data: {} });
    }
  );
};

const getMyRequestStatusCounts = (isAdmin) => (dispatch) => {
  dispatch({ type: GET_DASHBOARD_MY_REQUEST_STATUSES_START });
  const url = `dashboard/status_count_user?dashboard_type=${'sarovar'}`;
  axiosInstance.get(url, {}).then(
    (res) => {
      dispatch({
        type: GET_DASHBOARD_MY_REQUEST_STATUSES_FINISHED,
        data: res.data?.data || {},
      });
    },
    (err) => {
      console.log('category counts error', err);
      dispatch({ type: GET_DASHBOARD_MY_REQUEST_STATUSES_FINISHED, data: {} });
    }
  );
};

const getUserRequestStatusCounts = (isAdmin) => (dispatch) => {
  dispatch({ type: GET_DASHBOARD_USER_REQUEST_STATUSES_START });
  const url = `dashboard/status_count_owner?dashboard_type=${'sarovar'}`;
  axiosInstance.get(url, {}).then(
    (res) => {
      dispatch({
        type: GET_DASHBOARD_USER_REQUEST_STATUSES_FINISHED,
        data: res.data?.data || {},
      });
    },
    (err) => {
      console.log('category counts error', err);
      dispatch({
        type: GET_DASHBOARD_USER_REQUEST_STATUSES_FINISHED,
        data: {},
      });
    }
  );
};

const getUserRequestsByStatus =
  (isAdmin, status, page = 1, searchPayload) =>
  (dispatch) => {
    let url =
      `dashboard/user_requests?dashboard_type=${'sarovar'}&status=` +
      status +
      `&page=${page}&per_page=10`;
    if (searchPayload?.query) {
      url += `&${searchPayload.type}=${searchPayload.query}`;
    }
    dispatch({ type: FETCH_DASHBOARD_USER_REQUESTS_START, status });
    axiosInstance.get(url, {}).then(
      (res) => {
        dispatch({
          status,
          type: FETCH_DASHBOARD_USER_REQUESTS_FINISHED,
          data: res.data?.data || [],
          pagination: res?.data?.pagination || {},
        });
      },
      (err) => {
        console.log('user requests error', err);
        dispatch({ type: FETCH_DASHBOARD_USER_REQUESTS_FINISHED, data: [] });
      }
    );
  };

const getMyRequestsByStatus =
  (isAdmin, status, searchPayload) => (dispatch) => {
    let url =
      `dashboard/my_dash_request?dashboard_type=${'sarovar'}&status=` + status;
    if (searchPayload?.query) {
      url += `&${searchPayload.type}=${searchPayload.query}`;
    }
    dispatch({ type: FETCH_DASHBOARD_MY_REQUESTS_START });
    axiosInstance.get(url, {}).then(
      (res) => {
        dispatch({
          type: FETCH_DASHBOARD_MY_REQUESTS_FINISHED,
          data: res.data?.data || [],
        });
      },
      (err) => {
        console.log('my requests error', err);
        dispatch({ type: FETCH_DASHBOARD_MY_REQUESTS_FINISHED, data: [] });
      }
    );
  };

const getDashboardById =
  ({ appId, userId, dashboard_type }) =>
  (dispatch) => {
    dispatch({ type: GET_DASHBOARD_BY_ID_START });
    axiosInstance
      .get(
        'dashboard/' +
          `dashboard_by_id?dashboard_id=${appId}&user_id=${userId}&dashboard_type=${dashboard_type}`,
        {}
      )
      .then(
        (res) => {
          dispatch({ type: GET_DASHBOARD_BY_ID_FINISHED, data: res.data });
        },
        (err) => {
          dispatch({ type: GET_DASHBOARD_BY_ID_FINISHED, data: [] });
          console.log('error', err);
        }
      );
  };

const getCatOptions = (appPayload) => (dispatch) => {
  dispatch({ type: GET_DASHBOARD_CAT_OPTIONS_START });
  axiosInstance.get('admin/function-list', {}).then(
    (res) => {
      dispatch({
        type: GET_DASHBOARD_CAT_OPTIONS_FINISHED,
        data: res.data?.data || [],
      });
    },
    (err) => {
      console.log('category counts error', err);
      dispatch({ type: GET_DASHBOARD_CAT_OPTIONS_FINISHED, data: [] });
    }
  );
};

const editDashboard = (dashboardId, appPayload, callback) => (dispatch) => {
  dispatch({ type: ADD_DASHBOARD_START });
  axiosInstance
    .post(`dashboard/add/dashboard?id=${dashboardId}`, appPayload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(
      (res) => {
        dispatch({ type: ADD_DASHBOARD_FINISHED });
        callback && callback({ success: true });
      },
      (err) => {
        console.log('add app erro', err);
        dispatch({ type: ADD_DASHBOARD_FINISHED });
        callback && callback({ success: false });
      }
    );
};

const addNewDashboard = (appPayload, callback) => (dispatch) => {
  dispatch({ type: ADD_DASHBOARD_START });
  axiosInstance
    .post('dashboard/add/dashboard', appPayload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(
      (res) => {
        dispatch({ type: ADD_DASHBOARD_FINISHED });
        callback && callback({ success: true });
      },
      (err) => {
        console.log('add app erro', err);
        dispatch({ type: ADD_DASHBOARD_FINISHED });
        callback && callback({ success: false });
      }
    );
};

const submitFeedback = (payload, callback) => (dispatch) => {
  axiosInstance.post('dashboard/createrating', payload).then(
    (res) => {
      callback && callback(true, res.data);
    },
    (err) => {
      console.log('error', err);
      callback && callback(false);
    }
  );
};

const getRefreshCycleOptions = (payload) => (dispatch) => {
  dispatch({ type: GET_REFRESH_CYCLE_OPTIONS_START });
  axiosInstance.get('dashboard/refresh_cycle_list', {}).then(
    (res) => {
      dispatch({
        type: GET_REFRESH_CYCLE_OPTIONS_FINISHED,
        data: res.data?.data || {},
      });
    },
    (err) => {
      console.log('category counts error', err);
      dispatch({ type: GET_REFRESH_CYCLE_OPTIONS_FINISHED, data: {} });
    }
  );
};

const createAccessRequest = (payload, callback) => (dispatch) => {
  // dashboard/dash_access_request
  dispatch({ type: REQUEST_DASHBOARD_ACCESS_START });
  axiosInstance.post('dashboard/dash_access_request', payload, {}).then(
    (res) => {
      dispatch({ type: REQUEST_DASHBOARD_ACCESS_FINISHED });
      callback && callback(res.data.success);
    },
    (err) => {
      console.log('error', err);
      dispatch({ type: REQUEST_DASHBOARD_ACCESS_FINISHED });
      callback && callback(false, err);
    }
  );
};

const getCustomDashADGroups = (appId, payload) => (dispatch) => {
  dispatch({ type: GET_CUSTOM_DASH_AD_GROUP_START });
  let url = `dashboard/getCustomDashADGroups?dashboard_id=${appId?.app_id}`;
  if (appId?.search) {
    url += `&search=${appId?.search}`;
  }
  axiosInstance.get(url).then(
    (res) => {
      dispatch({ type: GET_CUSTOM_DASH_AD_GROUP_FINISHED, data: res.data });
    },
    (err) => {
      dispatch({ type: GET_CUSTOM_DASH_AD_GROUP_FINISHED, data: {} });
      console.log('err', err);
    }
  );
};

const createNewDashADGroup = (payload, callback) => (dispatch) => {
  dispatch({ type: CREATE_NEW_DASH_AD_GROUP_START });
  axiosInstance.post('dashboard/createNewDashADGroup', payload, {}).then(
    (res) => {
      dispatch({ type: CREATE_NEW_DASH_AD_GROUP_FINISHED });
      callback && callback(res.data.success);
    },
    (err) => {
      console.log('error', err);
      dispatch({ type: CREATE_NEW_DASH_AD_GROUP_FINISHED });
      callback && callback(false, err);
    }
  );
};
const getCustomDashADMembersList = (appId, payload) => (dispatch) => {
  // console.log('Payload_Action:', payload);
  //console.log('appId_Action:', appId);
  dispatch({ type: GET_CUSTOM_DASH_AD_MEMBERS_START });
  let url = `dashboard/customDashGroup/active-users-list?dashboard_id=${appId?.dashboard_id}&group_id=${appId?.group_id}&page=${appId?.page}&per_page=${appId?.per_page}`;
  if (appId?.search) {
    url += `&search=${appId?.search}`;
  }
  axiosInstance.get(url).then(
    (res) => {
      dispatch({ type: GET_CUSTOM_DASH_AD_MEMBERS_FINISHED, data: res.data });
    },
    (err) => {
      dispatch({ type: GET_CUSTOM_DASH_AD_MEMBERS_FINISHED, data: {} });
      console.log('err', err);
    }
  );
};
const deactivateDashADGroup =
  (payload = {}, callback) =>
  (dispatch) => {
    const { appId, userId, status } = payload;
    dispatch({ type: DEACTIVATE_DASH_AD_GROUP_START });
    const url = `dashboard/deactivateDashADGroup`;
    axiosInstance.patch(url, payload).then(
      (res) => {
        dispatch({ type: DEACTIVATE_DASH_AD_GROUP_FINISHED, data: res });
        callback && callback(true, status);
      },
      (err) => {
        console.log('approve request err', err);
        dispatch({ type: DEACTIVATE_DASH_AD_GROUP_FINISHED, data: [] });
        callback && callback(false, status);
      }
    );
  };
const deactivateDashADMembers =
  (payload = {}, callback) =>
  (dispatch) => {
    const { appId, userId, status } = payload;
    dispatch({ type: DEACTIVATE_DASH_AD_MEMBERS_START });
    const url = `dashboard/customDashGroup/removeMembers`;
    axiosInstance.patch(url, payload).then(
      (res) => {
        dispatch({ type: DEACTIVATE_DASH_AD_MEMBERS_FINISHED, data: res });
        callback && callback(true, status);
      },
      (err) => {
        console.log('approve request err', err);
        dispatch({ type: DEACTIVATE_DASH_AD_MEMBERS_FINISHED, data: [] });
        callback && callback(false, status);
      }
    );
  };

const approveRequests =
  (payload = {}, callback) =>
  (dispatch) => {
    // dashboard/dash_access_request
    const { status } = payload;
    dispatch({ type: UPDATE_REQUEST_STATUS_START });
    const url = `dashboard/accept_reject_dashbaord?dashboard_id=${'sarovar'}`;
    axiosInstance.patch(url, payload).then(
      (res) => {
        dispatch({ type: UPDATE_REQUEST_STATUS_FINISHED, data: res });
        callback && callback(true, status);
      },
      (err) => {
        console.log('approve request err', err);
        dispatch({ type: UPDATE_REQUEST_STATUS_FINISHED, data: [] });
        callback && callback(false, status);
      }
    );
  };

// https://dx-api.lexyslabs.com/api/v1/dashboard/customDashGroup/addMembers
const ADDashGroupAddMembers =
  (payload = {}, callback) =>
  (dispatch) => {
    const { appId, userId, status } = payload;
    dispatch({ type: AD_DASH_GROUP_ADD_MEMBERS_STATUS_START });
    const url = `dashboard/customDashGroup/addMembers`;
    axiosInstance.patch(url, payload).then(
      (res) => {
        dispatch({
          type: AD_DASH_GROUP_ADD_MEMBERS_STATUS_FINISHED,
          data: res,
        });
        callback && callback(true, status);
      },
      (err) => {
        console.log('approve request err', err);
        dispatch({ type: AD_DASH_GROUP_ADD_MEMBERS_STATUS_FINISHED, data: [] });
        callback && callback(false, status);
      }
    );
  };

const getAppUsers = (appId, payload) => (dispatch) => {
  dispatch({ type: GET_DASH_USERS_START });

  let url = `dashboard/dashboard-owners?id=${appId}&role=users&page=${payload.page}&per_page=${payload.per_page}`;
  if (payload.search) {
    url += `&search=${payload.search}`;
  }
  axiosInstance.get(url).then(
    (res) => {
      dispatch({ type: GET_DASH_USERS_FINISHED, data: res.data });
    },
    (err) => {
      dispatch({ type: GET_DASH_USERS_FINISHED, data: {} });
      console.log('err', err);
    }
  );
};

const getAppOwners = (appId, payload) => (dispatch) => {
  dispatch({ type: GET_DASH_OWNERS_START });
  let url = `dashboard/dashboard-owners?id=${appId}&role=owners&page=${payload.page}&per_page=${payload.per_page}`;
  if (payload.search) {
    url += `&search=${payload.search}`;
  }
  axiosInstance.get(url).then(
    (res) => {
      dispatch({ type: GET_DASH_OWNERS_FINISHED, data: res.data || {} });
    },
    (err) => {
      dispatch({ type: GET_DASH_OWNERS_FINISHED, data: {} });
      console.log('err', err);
    }
  );
};

const addUsersToApp = (payload, callback) => (dispatch) => {
  axiosInstance.put(API_BASE_URL + 'dashboard/dashboard-add', payload).then(
    (res) => {
      callback && callback(true, res.data);
    },
    (err) => {
      console.log('error', err);
      callback && callback(false);
    }
  );
};

const deactivateUSerFromApp = (payload, callback) => (dispatch) => {
  axiosInstance
    .delete(API_BASE_URL + 'dashboard/dashboard-remove', { data: payload })
    .then(
      (res) => {
        callback && callback(true, res.data);
      },
      (err) => {
        console.log('error', err);
        callback && callback(false);
      }
    );
};

const deleteDocuments =
  (payloadArr = []) =>
  (dispatch) => {
    const requests = payloadArr.map((itm) =>
      axiosInstance.delete(API_BASE_URL + 'dashboard/delete_dash_doc', {
        data: itm,
      })
    );
    axios
      .all(requests)
      .then(
        axios.spread((obj1, obj2) => {
          console.log('deletion complete');
        })
      )
      .catch((err) => {
        console.log('error while deleting');
      });
  };

const syncAdUsers = (appId, callback) => (dispatch) => {
  axiosInstance
    .get(
      API_BASE_URL + `dashboard/sync-azure-group-members?dashboard_id=${appId}`,
      {}
    )
    .then(
      (res) => {
        callback && callback(true, res.data);
      },
      (err) => {
        console.log('error', err);
        callback && callback(false);
      }
    );
};

const getDashboardWiseRatings =
  ({ page }) =>
  (dispatch) => {
    let url =
      `dash_rating/dashboard_wise_rating?dashboard_type=${'sarovar'}&page=` +
      `${page}&per_page=10`;
    dispatch({ type: 'FETCH_DASHBOARD_USER_RATINGS_START' });
    axiosInstance.get(url, {}).then(
      (res) => {
        dispatch({
          type: 'FETCH_DASHBOARD_USER_RATINGS_FINISHED',
          data: res.data?.data || [],
          pagination: res?.data?.pagination || {},
        });
      },
      (err) => {
        console.log('user ratings error', err);
        dispatch({ type: 'FETCH_DASHBOARD_USER_RATINGS_FINISHED', data: [] });
      }
    );
  };

const sarovarActions = {
  getDashboardsList,
  getCategoryCounts,
  getMyRequestStatusCounts,
  getUserRequestStatusCounts,
  getUserRequestsByStatus,
  getMyRequestsByStatus,
  getDashboardById,
  getCatOptions,
  addNewDashboard,
  submitFeedback,
  getRefreshCycleOptions,
  createAccessRequest,
  approveRequests,
  editDashboard,
  getAppUsers,
  getAppOwners,
  addUsersToApp,
  deactivateUSerFromApp,
  deleteDocuments,
  syncAdUsers,
  getDashboardWiseRatings,
  getCustomDashADGroups,
  createNewDashADGroup,
  deactivateDashADGroup,
  ADDashGroupAddMembers,
  getCustomDashADMembersList,
  deactivateDashADMembers,
};

export default sarovarActions;
